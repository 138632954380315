<template>
  <div>

    <b-card>
      <b-row>
        <b-col>
          <h3>{{ $store.state.clients.clientStats ? $store.state.clients.clientStats.full_name : 'Cliente Carregando' }}</h3>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-row>
        <b-col md="4">
          <label
            for="datepicker-start"
          >Data de início</label>
          <b-button
            v-if="$store.state.clients.startDate"
            variant="danger"
            size="sm"
            class="ml-1 btn-icon py-0"
            @click="()=>{$store.state.clients.startDate = ''; filterEvents();}"
          >
            <feather-icon
              icon="XIcon"
              class="my-05"
            />
          </b-button>
          <flat-pickr
            id="datepicker-start"
            v-model="$store.state.clients.startDate"
            class="form-control"
            :config="config"
            @on-change="filterEvents"
          />
        </b-col>
        <b-col md="4">
          <label
            for="datepicker-end"
          >Data de fim</label>
          <b-button
            v-if="$store.state.clients.endDate"
            variant="danger"
            size="sm"
            class="ml-1 btn-icon py-0"
            @click="()=>{$store.state.clients.endDate = ''; filterEvents();}"
          >
            <feather-icon
              icon="XIcon"
              class="my-05"
            />
          </b-button>
          <flat-pickr
            id="datepicker-end"
            v-model="$store.state.clients.endDate"
            class="form-control"
            :config="config"
            @on-change="filterEvents"
          />
        </b-col>
        <b-col md="4">
          <label
            for="unit"
          >Unidade</label>
          <v-select
            id="unit"
            v-model="unit"
            label="text"
            :reduce="o => o.value"
            :clearable="false"
            :options="unitsChoices"
            @input="updateGraphs()"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-card
      v-if="loaded"
      no-body
      class="card-revenue-budget"
    >
      <b-row class="mx-0">
        <b-col
          md="12"
          class="revenue-report-wrapper"
        >
          <div class="d-sm-flex justify-content-between align-items-center mb-3">
            <h4 class="card-title mb-50 mb-sm-0">
              {{ $store.state.clients.clientStats.total_events }} Aulas no periodo ({{ $store.state.clients.clientStats.alunos_unicos }} alunos)
            </h4>
          </div>
        </b-col>
      </b-row>
      <b-row class="mx-0">
        <b-col
          md="4"
          class="revenue-report-wrapper"
        >
          <vue-apex-charts
            type="donut"
            height="350"
            :options="prof.options"
            :series="prof.series"
          />

        </b-col>
        <b-col
          md="4"
          class="revenue-report-wrapper"
        >
          <vue-apex-charts
            type="donut"
            height="350"
            :options="modality.options"
            :series="modality.series"
          />

        </b-col>
        <b-col
          md="4"
          class="revenue-report-wrapper"
        >
          <vue-apex-charts
            type="bar"
            height="350"
            :options="dayOfWeek.options"
            :series="dayOfWeek.series"
          />

        </b-col>
      </b-row>

    </b-card>

    <b-card
      v-if="loaded"
    >
      <b-row class="mx-0">
        <b-col
          md="4"
        >
          <div class="d-sm-flex justify-content-between align-items-center">
            <h4 class="card-title mb-05 text-success">
              Professores: Aulas (Alunos)
            </h4>
          </div>
          <ul
            class="mb-3"
          >
            <li
              v-for="prof in $store.state.clients.clientStats.professors"
              :key="prof.professor__professorprofile__nome"
            >
              <b>{{ prof.professor__professorprofile__nome }}</b> : {{ prof.id }} ({{ prof.students_present }})
            </li>
          </ul>

        </b-col>
        <b-col
          md="4"
        >
          <div class="d-sm-flex justify-content-between align-items-center">
            <h4 class="card-title mb-05 text-primary">
              Modalidades: Aulas (Alunos)
            </h4>
          </div>
          <ul
            class="mb-3"
          >
            <li
              v-for="prof in $store.state.clients.clientStats.modality"
              :key="prof.modality__name"
            >
              <b>{{ prof.modality__name }}</b> : {{ prof.id }} ({{ prof.students_present }})
            </li>
          </ul>
        </b-col>
        <b-col
          md="4"
        >
          <div class="d-sm-flex justify-content-between align-items-center">
            <h4 class="card-title mb-05">
              Dias da semana: Aulas (Alunos)
            </h4>
          </div>
          <ul>
            <li
              v-for="prof in $store.state.clients.clientStats.day_of_week"
              :key="prof.day_of_week"
            >
              <b>{{ prof.day_of_week }}</b> : {{ prof.id }} ({{ prof.students_present }})
            </li>
          </ul>
        </b-col>
      </b-row>

    </b-card>

    <b-card
      v-if="$store.state.clients.clientStats"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <h4 class="card-title">
            Ultimas de aulas
          </h4>
        </b-col>

      </b-row>
      <app-timeline
        v-if="$store.state.clients.clientStats"
      >

        <app-timeline-item
          v-for="event in $store.state.clients.clientStats.last_events"
          :key="event.id"
          variant="primary"
          icon="UserIcon"
        >

          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>
              <b-link
                :to="{ name: 'event-edit', params: { id: event.id } }"
                class="aula-link"
              >
                Aula de <span class="modality-name">{{ event.modality__name }}</span> com <span class="professor-name">{{ event.professor__professorprofile__nome }}</span>
              </b-link>
            </h6>
            <small class="text-muted">{{ dateFormater.format(new Date(event.date_time)) }}</small>
          </div>
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <div />
            <b-badge
              pill
              variant="light-primary"
            >
              {{ event.local__clientprofile__name }}
            </b-badge>
          </div>

        </app-timeline-item>

      </app-timeline>
      <div class="d-flex justify-content-between flex-wrap">
        <div />
      </div>

    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BLink, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueApexCharts from 'vue-apexcharts'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt'

export default {
  components: {
    BButton,
    AppTimeline,
    AppTimelineItem,
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BBadge,
    BLink,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    userId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      config: {
        allowInput: false,
        locale: Portuguese,
      },
      unit: 'id',
      unitsChoices: [
        { value: 'id', text: 'Aulas' },
        { value: 'students_present', text: 'Alunos' },
      ],
      loaded: false,
      prof: {
        options: {
          dataLabels: {
            enabled: true,
            formatter(value, { seriesIndex, w }) {
              return `${w.config.series[seriesIndex]}`
            },
          },
          chart: {
            type: 'donut',
          },
          legend: {
            position: 'bottom',
          },

        },
        series: [],
      },
      modality: {
        options: {
          dataLabels: {
            enabled: true,
            formatter(value, { seriesIndex, w }) {
              return `${w.config.series[seriesIndex]}`
            },
          },
          chart: {
            type: 'donut',
          },
          legend: {
            position: 'bottom',
          },

        },
        series: [],
      },
      dayOfWeek: {
        options: {
          dataLabels: {
            enabled: true,
          },
          chart: {
            type: 'bar',
          },
          xaxis: {
            categories: [],
          },
        },
        series: [],
      },
    }
  },
  created() {
    this.dateFormater = new Intl.DateTimeFormat('pt-br', {
      hour12: false,
      year: 'numeric',
      day: 'numeric',
      month: 'long',
      hour: 'numeric',
      minute: '2-digit',
      weekday: 'long',
      timeZone: 'America/Sao_Paulo',
    })
    this.$store.commit('clients/clearClientStats')
    this.$store.dispatch('clients/getClientStats', this.userId).then(() => {
      this.updateGraphs()
    })
  },
  methods: {
    updateGraphs() {
      this.loaded = false
      if (!this.$store.state.clients.clientStats) {
        this.loaded = true
        return
      }
      this.dayOfWeek.series = []
      this.dayOfWeek.series.push({
        name: this.unitsChoices.find(item => item.value === this.unit).text,
        data: this.$store.state.clients.clientStats.day_of_week.map(item => item[this.unit]),
      })
      this.dayOfWeek.options.xaxis.categories = this.$store.state.clients.clientStats.day_of_week.map(item => item.day_of_week)

      this.prof.options.labels = this.$store.state.clients.clientStats.professors.map(item => item.professor__professorprofile__nome)
      this.prof.series = this.$store.state.clients.clientStats.professors.map(item => item[this.unit])
      this.modality.options.labels = this.$store.state.clients.clientStats.modality.map(item => item.modality__name)
      this.modality.series = this.$store.state.clients.clientStats.modality.map(item => item[this.unit])
      this.loaded = true
    },
    filterEvents() {
      this.loaded = false
      this.$store.commit('clients/clearClientStats')
      this.$store.dispatch('clients/getClientStats', this.userId).then(() => {
        this.updateGraphs()
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import "~flatpickr/dist/themes/airbnb.css";
.form-control[readonly] {
  background-color: inherit;
}

.aula-link {
  color: black;
  cursor: pointer;
  font-weight: 300;
  font-size: large;
}
.modality-name{
  color: $primary;
  font-weight: 500;
}
.professor-name{
  color: $success;
  font-weight: 500;
}
ul{
  list-style: none;
  padding: 0;
}
</style>
